<!-- 招生系统基础配置 -- 高考录取分数线 -- 新增 -->
<template>
	<edit-template class="form" @confirm="submit" @cancel="$confirm('取消新增?','提示',{type:'warning'}).then(res=>$router.back()).catch(()=>{});" :cancelRemind="false" confirmText="保存" cancelText="取消
      ">

    <div style="margin-left:  22rem;margin-bottom: 100rem;min-height: calc(100vh - 412rem)">
      <el-form :model="form" :rules="rules" ref="form" label-width="150rem">
        <el-form-item label="应用省份" prop="province_code">
          <el-select v-model="form.province_code" placeholder="请选择">
            <el-option v-for="item in Config.area" :label="item.address_name" :value="item.province_code"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="毕业年份" prop="grad_year">
          <el-select :popper-append-to-body="false" v-model="form.grad_year" placeholder="请选择" class="select">
            <el-option v-for="item in Config.year" :label="item + '年'" :value="item"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="学习方向" prop="direction_id">
          <el-select v-model="form.direction_id" placeholder="请选择" @change="directionChange">
            <el-option v-for="item in Config.direction" :label="item.direction_name" :value="item.direction_id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="考试方式" prop="exam_type_id">
          <el-select v-model="form.exam_type_id" placeholder="请选择">
            <el-option v-for="item in Config.exam_type" :label="item.exam_type_name" :value="item.exam_type_id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <el-form v-show="type === 1" :model="form1" :rules="rules" ref="form1" label-width="150rem">
        <el-form-item label="本科一批录取分数" prop="undergraduate_first_score">
          <el-input v-model="form1.undergraduate_first_score" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="本科二批录取分数" prop="undergraduate_second_score">
          <el-input v-model="form1.undergraduate_second_score" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="专科批录取分数" prop="junior_score">
          <el-input v-model="form1.junior_score" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="复读录取分数线" prop="repeat_score">
          <el-input v-model="form1.repeat_score" placeholder="请输入"></el-input>
        </el-form-item>
      </el-form>

      <el-form v-show="type === 3" :model="form2" :rules="rules" ref="form2" label-width="150rem">
<!--        <el-form-item label="招生本科线" prop="undergraduate_first_score">-->
<!--          <el-input v-model="form2.undergraduate_first_score" placeholder="请输入"></el-input>-->
<!--        </el-form-item>-->
        <h4 style="padding: 50rem 0 20rem 65rem">专业方向</h4>
        <div class="knowledge" v-for="(item, index) in form2.specialized">
          <div class="flex" style="background: #f4f4f4;padding: 20rem 40rem 20rem 0">
            <el-form-item label="专业" label-width="100rem" style="margin-bottom: 0" :prop="'specialized.'+index+'.major_id'" :rules="rules.major_id">
              <el-select :popper-append-to-body="true" v-model="item.major_id" @change="majorChange($event,index)">
                <el-option v-for="_item in Config.major" :label="_item.major_name" :value="_item.major_id" :disabled="_item.disabled">
              </el-option></el-select>
            </el-form-item>
            <el-form-item label="层次" label-width="100rem" style="margin-bottom: 0" :prop="'specialized.'+index+'.levels_id'" :rules="rules.levels_id">
              <el-select :popper-append-to-body="true" v-model="item.levels_id" @change="levelChange">
                <el-option v-for="_item in (item.major_id ? Config.levels : [])" :label="_item.levels_name" :value="_item.levels_id" :disabled="!!major_id_map[item.major_id][_item.levels_id]">
              </el-option></el-select>
            </el-form-item>
            <el-form-item label="录取分数" label-width="100rem" style="margin-bottom: 0" :prop="'specialized.'+index+'.score'" :rules="rules.score">
              <el-input placeholder="请输入" v-model="item.score"></el-input>
            </el-form-item>
            <el-form-item label="复读录取分数线" label-width="146rem" style="margin-bottom: 0" :prop="'specialized.'+index+'.repeat_score'" :rules="rules.repeat_score">
              <el-input placeholder="请输入" v-model="item.repeat_score"></el-input>
            </el-form-item>
          </div>
          <div class="flex" style="margin-left: 40rem">
            <el-button icon="el-icon-delete" circle="" @click="deleteKnowledge(index)" v-if="form2.specialized.length !== 1"></el-button>
            <el-button icon="el-icon-plus" circle="" @click="addKnowledge"></el-button>
          </div>
        </div>
      </el-form>

      <el-form v-show="type === 2" :model="form3" :rules="rules" ref="form3" label-width="150rem">
        <el-form-item label="本科录取分数" prop="undergraduate_first_score">
          <el-input v-model="form3.undergraduate_first_score" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="高职专科录取分数" prop="junior_score">
          <el-input v-model="form3.junior_score" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="复读录取分数线" prop="repeat_score">
          <el-input v-model="form3.repeat_score" placeholder="请输入"></el-input>
        </el-form-item>
      </el-form>
    </div>


</edit-template>
</template>

<script>
import $verify from '../../../../utils/verify';
import tools from '@/utils/tools'

export default {
	_config:{"route":{"path":"add","meta":{"title":"新增"}}},
  data() {
    return {
      type: 0,  //0为默认，1为文化生，2为艺术生，3为体育生
      form: {
        province_code: '',
        grad_year: '',
        direction_id: '',
        exam_type_id: ''
      },
      form1: {
        undergraduate_first_score: '',
        undergraduate_second_score: '',
        junior_score: '',
        repeat_score: ''
      },
      form2: {
        undergraduate_first_score: '',
        specialized: [
          {
            major_id: '',
            levels_id: '',
            score: '',
            repeat_score: ''
          }
        ],
      },
      form3: {
        undergraduate_first_score: '',
        junior_score: '',
        repeat_score: ''
      },
      major_id_map: {},
      rules: {
        province_code: [{required: true, message: "请选择应用省份"}],
        grad_year: [{required: true, message: "请选择毕业年份"}],
        direction_id: [{required: true, message: "请选择学习方向"}],
        exam_type_id: [{required: true, message: "请选择考试方式"}],
        undergraduate_first_score: [{required: true, message: "请输入分数线"}, {
          validator: this.$verify.toFixed1,
          message: '请检查分数格式，最多保留一位小数'
        }],
        undergraduate_second_score: [{required: true, message: "请输入分数线"}, {
          validator: this.$verify.toFixed1,
          message: '请检查分数格式，最多保留一位小数'
        }],
        junior_score: [{required: true, message: "请输入分数线"}, {
          validator: this.$verify.toFixed1,
          message: '请检查分数格式，最多保留一位小数'
        }],
        major_id: [{required: true, message: '请选择专业'}],
        levels_id: [{required: true, message: '请选择层级'}],
        score: [
          {required: true, message: '请输入分数'},
          {validator: $verify.toFixed1,message:'请检查分数格式，最多保留一位小数'}
        ],
        repeat_score: [{required: true, message: '请输入分数线'}],
      },
      Config: '',
    }
  },
  created() {
    this.Config = tools.getSession('gaokaoConfig');
  },
  methods: {
    //改变学习方向
    directionChange(e) {
      this.type = e;
      switch (e) {
        case 1:
          this.$refs.form2.resetFields();
          this.$refs.form3.resetFields();
          this.form3.specialized = [{major_id: '', levels_id: '', score: '', repeat_score: '',}];
          break;
        case 3:
          this.$refs.form1.resetFields();
          this.$refs.form3.resetFields();
          this.form3.specialized = [{major_id: '', levels_id: '', score: '', repeat_score: '',}];
          break;
        case 2:
          this.$refs.form1.resetFields();
          this.$refs.form2.resetFields();
          break;
      }
    },

    // 添加专业方向
    addKnowledge() {
      this.form2.specialized.push({major_id: '', levels_id: '', score: '', repeat_score: '',})
    },

    // 删除专业方向
    deleteKnowledge(index) {
      this.form2.specialized.splice(index, 1);
      this.checkMajor()
    },

    //检查艺术生专业和层级是否可选
    /*major_id_map:{
      ‘专业id’：{
        count： 改专业被选中的次数，
        1: 本科是否被选中，
        2: 高职专科是否被选中，
      }
    }*/
    checkMajor() {
      this.Config.major.forEach(item => {
        item.disabled = false;
      })
      let major_id_map = {};
      this.form2.specialized.forEach(item => {

        if (item.major_id) {
          if (Object.keys(major_id_map).includes(item.major_id.toString())) {
            major_id_map[item.major_id].count = ++major_id_map[item.major_id].count
          } else {
            if(major_id_map[item.major_id]){
              major_id_map[item.major_id].count = 1
            }else{
              major_id_map[item.major_id] = {count: 1}
            }
          }
          if(item.levels_id){
            major_id_map[item.major_id][item.levels_id] = true
          }
        }
      })
      Object.keys(major_id_map).forEach(item => {
        if (major_id_map[item].count === 2) {
          this.Config.major.forEach(_item => {
            if (_item.major_id.toString() === item) _item.disabled = true;
          })
        }
      })
      this.major_id_map = major_id_map;
    },

    majorChange(e,index) {
      this.form2.specialized[index].levels_id = ''
      this.checkMajor()
    },

    levelChange(){
      this.checkMajor()
    },

    // 提交数据
    submit() {
      let boo1 = false,
          boo2 = false,
          params = {...this.form};
      this.$refs.form.validate((res, e, p) => {
        if (res) boo1 = true
      })

      switch (this.type) {
        case 1:
          this.$refs.form1.validate((res, e, p) => {
            if (res) {
              Object.assign(params,this.form1)
              boo2 = true
            }
          });
          break;
        case 3:
          this.$refs.form2.validate((res, e, p) => {
            if (res) {
              Object.assign(params,this.form2)
              boo2 = true
            }
          });
          break;
        case 2:
          this.$refs.form3.validate((res, e, p) => {
            if (res) {
              Object.assign(params,this.form3)
              boo2 = true
            }
          });
          break;
      }

      if (boo1 & boo2) {
        this.$_register.post("/api/recruit/enroll/add", params).then(res => {
          this.$message({
            type: 'success',
            message: '新增成功',
            onClose: () => {
              this.$store.commit("setPage", 1);
              this.$router.back();
            }
          });
        })
      }

    },
  }
}
</script>

<style lang="scss" scoped>

.form {
  padding-top: 70rem;
}

::v-deep .el-form .el-input {
  min-width: 200rem;
}

::v-deep .knowledge .el-input{
  width: 130rem !important;
}

.knowledge {
  padding-left: 60rem;
  margin-top: 24rem;
  display: flex;
  align-items: center;

  .knowledge_box {
    display: flex;
    padding-top: 12rem;
    height: 60rem;
    background-color: #f4f4f4;
  }

  .add_knowledge {
    display: flex;
    padding-left: 40rem;
  }
}
</style>
